// Services.js
import React from "react";
import "../styles/Services.css";

function Services() {
  return (
    <>
      <div className="services">
        <div className="service">
          <h2>CLEANROOM PARTITION</h2>
          <p>
            Welcome to BIAS Turnkey Solution Pvt Ltd, where we offer an
            extensive range of Puff and Rockwool partition panels designed to
            meet your diverse needs...
          </p>
        </div>
        <div className="service">
          <h2>CLEANROOM DOORS</h2>
          <p>
            Welcome to BIAS Turnkey Solution Pvt Ltd, where we specialize in
            offering custom-designed doors tailored to meet the rigorous demands
            of industries such as Cleanroom Pharmaceutical, Healthcare...
          </p>
        </div>
        <div className="service">
          <h2>HEATING VENTILATION AND AIR CONDITIONING (HVAC)</h2>
          <p>
            Welcome to BIAS Turnkey Solution Pvt Ltd, where we understand the
            pivotal role of HVAC systems play across various sectors, including
            commercial, industrial, IT, pharmaceutical...
          </p>
        </div>
        {/* Add more services here */}
      </div>
    </>
  );
}

export default Services;
