// import React from "react";
// import { Button } from "reactstrap";
// import "../styles/Main.css";
// function Main() {
//   return (
//     <div className="main">
//       <div
//         style={{
//           flex: 1,
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center",

//           height: "85vh",
//         }}
//       >
//         <h2 style={{ textAlign: "center", maxWidth: "80%" }}>
//           Elevating cleanroom excellence with precision and efficiency
//         </h2>
//         <p>
//           Your go-to for top-quality cleanroom solutions, offering expert
//           design, installation, and unmatched efficiency.
//         </p>
//         <div>
//           <Button color="primary">Explore our services</Button>
//           <Button style={{ marginLeft: 12 }} outline>
//             Contact us for details ->
//           </Button>
//         </div>
//         <img
//           style={{ marginTop: 50 }}
//           src="https://lotustechnicals.com/wp-content/uploads/2022/03/Turnkey-projects-slider.jpg"
//         />
//       </div>

//       <h2 style={{ textAlign: "center", marginBottom: 10 }}>
//         Why to choose us
//       </h2>
//       <div class="container">
//         <div>
//           <h2>Why we</h2>
//           <p>
//             Welcome to BIAS Turnkey Solution Pvt Ltd, your top provider of
//             high-quality cleanroom solutions. We specialize in Cleanroom Panels,
//             Doors, Flooring, and equipment such as Pass boxes and HVAC units.
//             Our focus is on cost-effective projects with precise engineering and
//             layouts. Our dedicated team ensures excellence in design,
//             installation, testing, and documentation. Contact us today for
//             tailored cleanroom solutions.
//           </p>
//         </div>
//         <div>
//           <h2>What we provide</h2>
//           <p>
//             Your one-stop solution for all cleanroom needs. We offer
//             partitioning, flooring, equipment, lighting, and HVAC systems. With
//             expert design, cost estimation, scheduling, installation, and
//             commissioning, we guarantee seamless execution and satisfaction.
//             Trust BIAS for superior results in your cleanroom projects.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Main;
import React from "react";
import { Button } from "reactstrap";
import "../styles/Main.css";

function Main() {
  return (
    <div className="main">
      <div className="hero-section">
        <h2>Elevating cleanroom excellence with precision and efficiency</h2>
        <p>
          Your go-to for top-quality cleanroom solutions, offering expert
          design, installation, and unmatched efficiency.
        </p>
        <div className="buttons">
          <Button color="primary">Explore our services</Button>
          <Button style={{ marginLeft: 12 }} outline>
            Contact us for details ->
          </Button>
        </div>
        <img
          src="https://lotustechnicals.com/wp-content/uploads/2022/03/Turnkey-projects-slider.jpg"
          alt="Cleanroom"
          className="hero-image"
        />
      </div>

      <div className="features">
        <h2 className="section-title">Why choose us</h2>
        <div className="container">
          <div>
            <h3>Expertise</h3>
            <h5>
              Welcome to BIAS Turnkey Solution Pvt Ltd, your top provider of
              high-quality cleanroom solutions. Our dedicated team ensures
              excellence in design, installation, testing, and documentation.
            </h5>
          </div>
          <div>
            <h3>Comprehensive Solutions</h3>
            <h5>
              Your one-stop solution for all cleanroom needs. We offer
              partitioning, flooring, equipment, lighting, and HVAC systems.
              Trust BIAS for superior results in your cleanroom projects.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
