// Footer.js
import React from "react";
import "../styles/Footer.css";
function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="client-info">
          <h3>OUR CLIENTS:</h3>
          <p>
            Over the years, BIAS Turnkey Solution Pvt Ltd has had the privilege
            of serving a diverse clientele spanning various industries and
            sectors. Our client portfolio includes multinational corporations,
            small and medium-sized enterprises, government agencies, and
            non-profit organizations, among others.
          </p>
        </div>
        <div className="contact-info">
          <h3>CONTACT US:</h3>
          <address>
            BIAS Turnkey Solutions Pvt Ltd. <br />
            374 C4 Sayed H Sultanchawl, Hall road, <br />
            Kurla, Mumbai, Maharashtra, India, 400070 <br />
            <a href="http://www.biassolution.com">www.biassolution.com</a>{" "}
            <br />
            <a href="mailto:info@biassolution.com">
              info@biassolution.com
            </a>{" "}
            <br />
            <a href="mailto:sales@biassolution.com">sales@biassolution.com</a>
          </address>
        </div>
      </div>
    </div>
  );
}

export default Footer;
