import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import logo from "../images/logo.png";
import "../styles/Navbar.css";

function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="header">
      {/*  */}
      <Navbar light expand="md">
        <NavbarBrand href="/">
          <div style={{ flexDirection: "row", alignItems: "center" }}>
            <img className="home_icon" src={logo} alt="" />
            <text
              style={{
                marginLeft: 10,
                fontFamily: "cursive",
                fontSize: "25px",
              }}
            >
              Bias Solutions
            </text>
          </div>
        </NavbarBrand>

        <NavbarToggler className="nav_toggler" onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto header__links" navbar>
            <NavItem>
              <NavLink className="nav_link" href="/">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav_link" href="/services">
                Services
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav_link" href="/contact">
                Contact Us
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavigationBar;
